import * as coreEntities from '_core/store/index';
import { CLIENT } from '_core/variables/constant';
import { useSelector } from 'react-redux';

export function useOrganization() {
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const tenantData = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const featureFlags = useSelector((state) => coreEntities.organization.selectors.getFeatureFlags(state));
  const canCreateGroup = useSelector((state) => coreEntities.organization.selectors.getCanCreateGroup(state));
  const canCreateCourse = useSelector((state) => coreEntities.organization.selectors.getCanCreateCourse(state));
  // const showLogout = client !== CLIENT.SANTILLANA_PILOTO;
  const canChangeAvatar = client !== CLIENT.SANTILLANA_PILOTO;
  const hasSupport = client !== CLIENT.SANTILLANA_PILOTO;
  const showAccountTab = client !== CLIENT.SANTILLANA_PILOTO;
  const showDisregardedPrograms = client !== CLIENT.SANTILLANA_PILOTO && client !== CLIENT.SANTILLANA_INTEGRATIONS;
  const showKidsMode = client === CLIENT.SANTILLANA_PILOTO;
  const isTangerine = client === CLIENT.TANGERINE;
  const hideLibrary = client === CLIENT.SANTILLANA_PILOTO;
  const hideTeacher = client === CLIENT.SANTILLANA_PILOTO;
  const hideEducationLevelGroup = client === CLIENT.SANTILLANA_PILOTO || client === CLIENT.SANTILLANA_INTEGRATIONS;
  const canEditProfileInfo = client !== CLIENT.SANTILLANA_PILOTO;
  const canEditCourse = client !== CLIENT.SANTILLANA_PILOTO;
  const logoutRedirect = client === CLIENT.SANTILLANA_PILOTO || client === CLIENT.SANTILLANA_INTEGRATIONS || client === CLIENT.SANTILLANA_ALFA;

  return {
    client,
    isTangerine,
    tenantConfig: {
      ...tenantData,
      ...featureFlags,
      hideTeacher: hideTeacher,
      showLogout: true,
      canChangeAvatar: canChangeAvatar,
      hasSupport: hasSupport,
      showAccountTab: showAccountTab,
      canCreateGroup: canCreateGroup,
      canCreateCourse: canCreateCourse,
      showDisregardedPrograms: showDisregardedPrograms,
      showKidsMode: showKidsMode,
      hideLibrary: hideLibrary,
      hideEducationLevelGroup: hideEducationLevelGroup,
      canEditProfileInfo: canEditProfileInfo,
      canEditCourse: canEditCourse,
      logoutRedirect: logoutRedirect,
    },
  };
}

import * as Sentry from '@sentry/react';
import { changeFavicon, frontoffice, i18next } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as coreEntities from '_core/store/index';
import { CLIENT } from '_core/variables/constant';
import resources from 'app/translations/resources';
import { ROUTES } from 'app/utils/constants/paths';
import { notificationsTypes } from 'app/utils/notifications';
import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
let callbackToken = null;

const AppContainer = ({ routerHistory }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  const scopes = useSelector((state) => coreEntities.langs.selectors.getLangScopes(state));
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const user = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const history = useHistory();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      frontoffice.ui.actions.setUiColors({
        color: getComputedStyle(document.documentElement).getPropertyValue('--color-first'),
        colorAlpha: getComputedStyle(document.documentElement).getPropertyValue('--color-first-alpha'),
        colorGradient1: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-1'),
        colorGradient2: getComputedStyle(document.documentElement).getPropertyValue('--color-gradient-2'),
      })
    );
  }, []);

  useEffect(() => {
    if (typeof window.tangeriniaWidget !== 'undefined' && typeof window.tangeriniaWidget.user !== 'undefined' && user?.guid) {
      console.log('window.tangeriniaWidget in user useEffect: ', window.tangeriniaWidget);
      window.tangeriniaWidget.setUser(user?.role_name.toUpperCase(), user?.name, user?.avatar);
    }
  }, [user]);

  // useEffect(() => {
  //   if (client && isAuthorized) {
  //     let amplitudeKey = clientIdAmplitude[window.location.origin];
  //     amplitude.getInstance().init(amplitudeKey, user.guid);
  //     amplitude.getInstance().setUserProperties({ client: client, role: user.role_name, source: isTeams() ? 'MS Teams' : user.source });
  //   }
  // }, [client, isAuthorized]);

  useEffect(() => {
    if (tenant?.guid) {
      window.tenant = tenant.guid;
      let tenantName = tenant.guid;
      let languageKeys = Object.keys(resources);
      languageKeys = languageKeys.filter((lang) => lang.length === 2);
      languageKeys.forEach(function (lang) {
        resources[lang].map((langScope, index) => {
          Object.keys(langScope).forEach(function (langScopeNs) {
            let langScopeTenant = {};
            let allScopes = {};
            if (tenantName !== CLIENT.TANGERINE && resources[lang + '_' + tenantName]) {
              langScopeTenant = resources[lang + '_' + tenantName][index];
              allScopes = { ...langScopeTenant[langScopeNs], ...langScope[langScopeNs] };
            } else {
              allScopes = langScope[langScopeNs];
            }
            i18next.addResources(lang, langScopeNs, allScopes);
          });
        });
      });
    }
  }, [tenant]);

  function changeLanguageTenant(langId) {
    dispatch(coreEntities.i18n.actions.setLanguage(langId));
  }

  useEffect(() => {
    if (isAuthorized) {
      checkApiHealth();
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
    }
    dispatch(frontoffice.educationLevels.actions.getEducationLevels());
    dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    dispatch(frontoffice.langs.actions.getLangScopes());
  }, [isAuthorized]);

  useEffect(() => {
    if (user?.guid && tenant?.guid) {
      initPosthog();
    }
  }, [user, tenant]);

  // get LMS
  useEffect(() => {
    if (!lms) dispatch(coreEntities.organization.actions.getOrganization());
  }, [lms]);

  // get name and favicon
  useEffect(() => {
    if (scopes && scopes.length > 0) {
      setAppNameAndFavicon();
    }
  }, [scopes]);

  useEffect(() => {
    const routes = Object.values(ROUTES)
      .map((route) => ({
        path: route,
      }))
      .sort((a, b) => b.path.length - a.path.length);

    Sentry.init({
      dsn: 'https://c583e7250991f783148988b55836f209@o4505759437029376.ingest.us.sentry.io/4506257572626432',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV5BrowserTracingIntegration({ history: routerHistory, routes, matchPath }),
        Sentry.thirdPartyErrorFilterIntegration({
          // Specify the application keys that you specified in the Sentry bundler plugin
          filterKeys: ['tangerine-frontoffice'],

          // Defines how to handle errors that contain third party stack frames.
          // Possible values are:
          // - 'drop-error-if-contains-third-party-frames'
          // - 'drop-error-if-exclusively-contains-third-party-frames'
          // - 'apply-tag-if-contains-third-party-frames'
          // - 'apply-tag-if-exclusively-contains-third-party-frames'
          behaviour: 'drop-error-if-contains-third-party-frames',
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }, []);

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response.hasOwnProperty('error')) {
      history.push('/error');
    }
  }

  function setAppNameAndFavicon() {
    let theName = scopes.filter((scope) => scope.scope === 'name');
    if (theName.length > 0) {
      document.title = theName[0].json;
    }
    let theFavicon = scopes.filter((scope) => scope.scope === 'favicon');
    if (theFavicon.length > 0) {
      changeFavicon(theFavicon[0].json);
    }
  }

  function initPosthog() {
    posthog.identify(user.guid, { client: tenant.guid, email: user.email, name: user.name + ' ' + user.lastname });
  }

  return <></>;
};

export default AppContainer;
